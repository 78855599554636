function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

function fetchModels(term) {
  let url = `/models/autocomplete.html?term=${term}`;
  const brandId = $("#car_brand_id").val();
  if (brandId) {
    url += `&brand_id=${brandId}`;
  }
  const selectedModels = $("#selected_models").val();
  if (selectedModels) {
    url += `&selected_models=${selectedModels}`;
  }
  fetch(url)
    .then((response) => response.text())
    .then((html) => {
      if (html) {
        $("#model_filter_dropdown").html(html);
        $("#model_filter_dropdown").show();
      } else {
        $("#model_filter_dropdown").hide();
      }
    });
}

$(function () {
  $("#car_status_filter_input").on("click", function () {
    $("#car_status_filter_dropdown").toggle();
  });

  $("input[name='has_car_status[]']").on("click", function () {
    const values = $("input[name='has_car_status[]']:checked")
      .map(function () {
        return $(this).val();
      })
      .get();
    $("#car_status_filter_input").val(values.join(", "));
  });

  // Actualización de combo de modelos cuando cambia la marca
  $("#car_brand_id").on("change", function () {
    $("#car_model_filter_input").val("");
    $("#has_model").val("");
    $("#model_filter_dropdown").hide();
  });

  $("#car_model_filter_input").on("click", function (event) {
    if (!$("#model_filter_dropdown").is(":visible") && event.target.value.length > 0) {
      fetchModels(event.target.value);
    }
  });

  $("#car_model_filter_input").on(
    "keyup",
    debounce(function (event) {
      fetchModels(event.target.value);
    }, 200)
  );

  $(document).on("click", "input[name='has_model[]']", function () {
    const values = $("input[name='has_model[]']:checked")
      .map(function () {
        return $(this).val();
      })
      .get();
    $("#selected_models").val(values.join(" "));
  });

  $(document).on("click", function (event) {
    if (
      event.target.closest("#car_model_filter_input") ||
      $(event.target).closest("#model_filter_dropdown").length > 0
    ) {
      return;
    }
    $("#model_filter_dropdown").hide();
  });

  $(document).on("click", function (event) {
    if (
      event.target.closest("#car_status_filter_input") ||
      $(event.target).closest("#car_status_filter_dropdown").length > 0
    ) {
      return;
    }
    $("#car_status_filter_dropdown").hide();
  });
});
