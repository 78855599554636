jQuery ($) ->

# Actualización de combo de modelos cuando cambia la marca
  $('#car_brand_id').change ->
    $.getJSON "/brands/#{ $(this).val() }/models.json?page=all", (data) ->
      items = [ '<option value=""> </option>' ]
      $.each data, (key, val) ->
        items.push("<option value='#{ val.id }'>#{val.name}</option>")
      $('#car_model_id').html(items.join(''))

  # Actualización de combo de subtipos de eventos cuando cambia el tipo de evento
  $('#event_event_type_id').change ->
    $.getJSON "/event_types/#{ $(this).val() }/event_subtypes.json", (data) ->
      items = [ '<option value=""> </option>' ]
      $.each data, (key, val) ->
        items.push('<option value="' + val.id + '">' + val.name + '</option>')
      $('#event_event_subtype_id').html(items.join(''))

  # Algunas máscaras comunes
  $('#company_cuit').mask('99-99999999-9')

  $('#event_flexible_cost').priceFormat({ prefix: '' })

  # Pone el focus en el primer campo visible
  $("input:visible:enabled:first").focus()

  $('.js_ref_type_radio').click (event) ->
    target = $(event.currentTarget)
    if target.attr('id') == 'mailing_config_ref_type_EventSubtype'
      $("div.form-group.radio_buttons.optional.mailing_config_status").slideDown()
    else
      $("div.form-group.radio_buttons.optional.mailing_config_status").slideUp()
    $('.js_ref_type_radio').parents('.js-mc-block').find('.js-mc-select').prop('disabled', true)
    target.parents('.js-mc-block').find('.js-mc-select').prop('disabled', false)


  ##### Autocomplete para conductores #####

  $('.person-autocomplete').on 'change', (event) ->
    target = $(event.currentTarget)
    if target.val().length == 0
      id_field = $(target).data('id-field')
      $("##{id_field}").val('')

  $('.person-autocomplete').autocomplete
    source: '/people/autocomplete.json?company_id=' + if $("#has_company").val() then $("#has_company").val() else ''
    minLength: 2
    select: ( event, ui ) ->
      $(event.target).val(ui.item.name)
      id_field = $(event.target).data('id-field')
      $("##{id_field}").val(ui.item.id)
      false

  $('.person-autocomplete').autocomplete('instance')._renderItem = ( ul, item ) ->
    $("<li>").append("<b>#{item.name}</b><br>#{item.company}").appendTo( ul )

  # Handles multiple autocomplete fields in the same page
  $('.person-autocomplete').on 'focus', ->
    $(this).autocomplete
      source: '/people/autocomplete.json?company_id=' + if $("#has_company").val() then $("#has_company").val() else ''
      minLength: 2
      select: ( event, ui ) ->
        $(event.target).val(ui.item.name)
        id_field = $(event.target).data('id-field')
        $("##{id_field}").val(ui.item.id)
        false

    $(this).autocomplete('instance')._renderItem = ( ul, item ) ->
      $("<li>").append("<b>#{item.name}</b><br>#{item.company}").appendTo( ul )


  ##### Autocomplete para empresas #####

  $('.company-autocomplete').on 'change', (event) ->
    target = $(event.currentTarget)
    if target.val().length == 0
      id_field = $(target).data('id-field')
      $("##{id_field}").val('')

  $('.company-autocomplete').autocomplete
    source: '/companies/autocomplete.json'
    minLength: 2
    select: ( event, ui ) ->
      $(event.target).val(ui.item.name)
      id_field = $(event.target).data('id-field')
      $("##{id_field}").val(ui.item.id)
      false

  $('.company-autocomplete').autocomplete('instance')._renderItem = ( ul, item ) ->
    $("<li>").append("<b>#{item.name}</b><br>CUIT: #{item.cuit}").appendTo( ul )

  # Handles multiple autocomplete fields in the same page
  $('.company-autocomplete').on 'focus', ->
    $(this).autocomplete
      source: '/companies/autocomplete.json'
      minLength: 2
      select: ( event, ui ) ->
        $(event.target).val(ui.item.name)
        id_field = $(event.target).data('id-field')
        $("##{id_field}").val(ui.item.id)
        false

    $(this).autocomplete('instance')._renderItem = ( ul, item ) ->
      $("<li>").append("<b>#{item.name}</b><br>CUIT: #{item.cuit}").appendTo( ul )

  $('#company_modal').on 'show.bs.modal', (event) ->
    $('#person_modal').modal('hide')
    company_id = $(event.relatedTarget).data('id')
    $.get "/companies/#{ company_id }", (html) ->
      $('#company_modal .modal-body').html(html)

  $('#person_modal').on 'show.bs.modal', (event) ->
    $('#company_modal').modal('hide')
    person_id = $(event.relatedTarget).data('id')
    title = $(event.relatedTarget).data('title')
    $.get "/people/#{ person_id }", (html) ->
      if title
        $('#person_modal .modal-header h3').html(title)
      $('#person_modal .modal-body').html(html)
